<template>
    <ExpandableCell :row="row" :levelIndent="levelIndent"
        :displayClass="[{'fw-bold': boldDisplay}, userClass]" />
</template>

<script setup lang="ts">
import type { NodeItem } from './DataObject.NodeItem';
import type { DataColumn } from 'o365-datagrid';

import ExpandableCell from './components.ExpandableCell.vue'
import { computed } from 'vue';

export interface IProps {
    row: NodeItem,
    column: DataColumn,

    levelIndent?: number,
    getClass?: (row: NodeItem) => any,
    boldDisplay?: boolean,
};

const props = defineProps<IProps>();

const userClass = computed(() => {
    return props.getClass
        ? props.getClass(props.row) ?? ''
        : '';
})
</script>